import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { timeStringToStruct, timeStructToString } from '@desquare/utils';
import {
  NgbDropdownModule,
  NgbTimeStruct,
  NgbTimepicker,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  standalone: true,
  imports: [
    FormsModule,
    NgbDropdownModule,
    NgbTimepicker,
    NgbTooltip,
    TranslateModule,
  ],
  selector: 'designage-timepicker',
  template: `
    @if (asInputField) {
      <div
        [ngbTooltip]="
          showSecond ? ('HH_MM_SS' | translate) : ('HH_MM' | translate)
        "
        ngbDropdown
        [container]="container ?? 'body'"
        autoClose="outside"
        (openChange)="onTimeChange($event)"
      >
        <span
          ngbDropdownToggle
          class="form-control form-control-dark time-display"
          [class.disabled]="disabled"
          [class.small]="small"
          >{{ getDisplayTime() }}
        </span>
        <div
          ngbDropdownMenu
          class="form-control form-control-dark shadowed-box time-picker"
        >
          <span ngbDropdownItem class="time-picker">
            <ngb-timepicker
              [(ngModel)]="pickerTime"
              [seconds]="showSecond"
              [hourStep]="hourStep"
              [minuteStep]="minuteStep"
              [secondStep]="secondStep"
              size="small"
            />
          </span>
        </div>
      </div>
    } @else {
      <div
        [ngbTooltip]="
          showSecond ? ('HH_MM_SS' | translate) : ('HH_MM' | translate)
        "
        ngbDropdown
        [container]="container ?? 'body'"
        autoClose="outside"
        placement="bottom"
        (openChange)="onTimeChange($event)"
      >
        <span class="icon" ngbDropdownToggle>
          <i class="ri-pencil-fill text-primary hover-fade-target"></i>
        </span>
        <div
          ngbDropdownMenu
          class="form-control form-control-dark shadowed-box time-picker"
        >
          <span ngbDropdownItem class="time-picker">
            <ngb-timepicker
              [(ngModel)]="pickerTime"
              [seconds]="showSecond"
              [hourStep]="hourStep"
              [minuteStep]="minuteStep"
              [secondStep]="secondStep"
              size="small"
            />
          </span>
        </div>
      </div>
    }
  `,
  styleUrls: ['./timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerComponent {
  _minTime = '';
  @Input() set minTime(value: string) {
    this._minTime = value;
    if (this._minTime !== '') {
      if (this.time < this._minTime) {
        const newTimeStruct = timeStringToStruct(this._minTime);
        newTimeStruct.hour = newTimeStruct.hour + 1;
        this.time = timeStructToString(newTimeStruct, this.showSecond);
      }
    }
  }
  get minTime() {
    return this._minTime;
  }
  @Input()
  set time(value: string) {
    this._time = value ? value : this.showSecond ? '00:00:00' : '00:00';
    this.pickerTime = timeStringToStruct(this._time);
  }
  get time() {
    return this._time;
  }
  @Output() timeChange = new EventEmitter<string>();

  @Input() asInputField = true;
  @Input() showSecond = true;
  @Input() hourStep = 1;
  @Input() minuteStep = 1;
  @Input() secondStep = 1;
  @Input() disabled = false;
  @Input() container: 'body' | null = 'body';
  @Input() small = false;
  @Input() placeholder = '00:00';
  pickerTime!: NgbTimeStruct;
  _time!: string;

  constructor() {}

  getFormattedMinTime() {
    if (this.minTime === '') return undefined;
    const timeStruct = timeStringToStruct(this.minTime);

    return timeStructToString(timeStruct, this.showSecond);
  }
  getDisplayTime() {
    // HH:mm or HH:mm:ss
    if (this.disabled) {
      return this.placeholder;
    }
    return timeStructToString(this.pickerTime, this.showSecond);
  }

  onTimeChange(open: boolean) {
    const minTime = this.getFormattedMinTime();
    let newTime = this.getDisplayTime();
    if (!open && this.time !== newTime) {
      if (minTime && minTime !== '' && newTime < minTime) {
        newTime = minTime;
        this.pickerTime = timeStringToStruct(newTime);
      }
      this.timeChange.emit(newTime);
    }
  }

  onSetTime() {
    const newTime = this.getDisplayTime();
    if (this.time !== newTime) {
      this.timeChange.emit(newTime);
    }
  }
}
